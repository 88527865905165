import { MenuOutlined } from '@ant-design/icons';
import { Button, Grid, Space } from 'antd';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { SelectApp } from 'sailor-ui/SelectApp';
import { useScrollHandler } from 'services';
import styles from './Header.module.scss';
import { MainDrawer } from './MainDrawer';
import { NavMenu } from './NavMenu';
import { useRouter } from 'next/router';

const Header = () => {
  const { lg: responsive, xl } = Grid.useBreakpoint();
  const { stickyHeader } = useScrollHandler({ breakpoint: responsive });
  const router = useRouter();

  const [mainDrawer, setMainDrawer] = useState<boolean>(false);

  const showDrawer = (): void => {
    setMainDrawer(true);
  };

  const onClose = (): void => {
    setMainDrawer(false);
  };

  useEffect(() => {
    if (xl) {
      onClose();
    }
  }, [xl, mainDrawer]);

  return (
    <>
      <header
        className={cn(styles.headerWrapper, {
          [styles.headerScrollDown]: stickyHeader,
          [styles.headerScrollUp]: !stickyHeader,
        })}
      >
        <div className={styles.wrap}>
          <div className={styles.selectApp}>
            <SelectApp
              onHomeClick={() => {
                router.push('/');
              }}
              currentApp="theboat_db"
            />
          </div>

          {xl && <NavMenu responsive={mainDrawer} />}

          {xl && (
            <Space style={{ width: '100%', justifyContent: 'end' }}>
              {/* <Link href={'https://theboatdb.com/login'}>
                <a className={`${styles.loginButtonDrawer} ant-btn ant-btn-ghost`}>Login</a>
              </Link>
              <GoldButton /> */}
            </Space>
          )}
        </div>

        {!xl && (
          <Button
            type="text"
            onClick={showDrawer}
            style={{ padding: '0 6px' }}
            icon={<MenuOutlined style={{ fontSize: 19 }} />}
          />
        )}
      </header>
      <MainDrawer goBack={false} onClose={onClose} visible={mainDrawer}>
        <NavMenu responsive={mainDrawer} />
        {/* <div className={styles.other}>
          <Link href={'https://theboatdb.com/login'}>
            <a className={styles.loginButtonDrawer}>Login</a>
          </Link>
        </div> */}
      </MainDrawer>
    </>
  );
};

export default Header;
